import * as enLocale from './lang/en.json'
import * as deLocale from './lang/de.json'
import * as plLocale from './lang/pl.json'
import * as csLocale from './lang/cs.json'

export default defineI18nConfig(() => ({
  legacy: false,
  locales: ['en', 'pl', 'de', 'cs'],
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: enLocale,
    de: deLocale,
    pl: plLocale,
    cs: csLocale,
  },
  datetimeFormats: {
    cs: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    en: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    pl: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    de: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
}))
